.card-img-top {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.tip-bubble {
  background-color: #C3887B;
}

.tip-bubble-2 {
  background-color: #68645B
}